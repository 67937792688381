/** ** */
import { Icon, IconProps, nature, PropsOf } from "@nature-ui/core";

const SVG = nature("svg");
type HTML_SVG = PropsOf<typeof SVG>;

export const DocsIcon = (props: IconProps) => {
  return (
    <Icon size="lg" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M11.333 6.71257L4.84196 5.0224C4.74306 4.99667 4.63928 4.99305 4.53868 5.01183C4.43807 5.03062 4.34336 5.07129 4.26191 5.1307C4.18045 5.19011 4.11445 5.26665 4.06902 5.35437C4.0236 5.44209 3.99998 5.53863 4 5.63649V13.9091C3.99997 14.0488 4.04805 14.1845 4.13684 14.2955C4.22563 14.4065 4.35018 14.4865 4.49131 14.5232L11.333 16.3044V6.71257Z"
        className="fill-primary-300"
      />
      <path
        d="M19.738 5.13058C19.6563 5.07172 19.5616 5.03139 19.4611 5.01264C19.3606 4.9939 19.257 4.99724 19.1581 5.0224L12.667 6.71256V16.3044L19.5087 14.5226C19.6497 14.4859 19.7742 14.406 19.863 14.2951C19.9518 14.1843 19.9999 14.0487 20 13.9091V5.63648C20.0001 5.5386 19.9764 5.44203 19.931 5.35429C19.8855 5.26655 19.8195 5.18999 19.738 5.13058V5.13058Z"
        className="fill-primary-400"
      />
      <path
        d="M19.8587 15.7546L12.5256 17.6637C12.1812 17.7527 11.8181 17.7527 11.4737 17.6637L4.14066 15.7546C4.092 15.7418 4.04733 15.7215 4 15.7056V18.3636C4 18.5324 4.07023 18.6943 4.19525 18.8136C4.32027 18.933 4.48984 19 4.66664 19H19.3334C19.5102 19 19.6797 18.933 19.8047 18.8136C19.9298 18.6943 20 18.5324 20 18.3636V15.7018C19.9527 15.7183 19.908 15.7387 19.8587 15.7546Z"
        className="fill-primary-500"
      />
    </Icon>
  );
};

export const GuidesIcon = (props: HTML_SVG) => {
  return (
    <Icon size="lg" viewBox="0 0 24 24" fill="none" color="white" {...props}>
      <path
        d="M3.16775 9.96875L6.22651 13.7187C6.29818 13.8066 6.38908 13.8774 6.49246 13.9261C6.59583 13.9748 6.70905 14 6.82373 14V10.25C6.82373 9.65326 7.06543 9.08097 7.49565 8.65901C7.92587 8.23705 8.50937 8 9.1178 8L16 8V5.75C16 5.55109 15.9194 5.36032 15.776 5.21967C15.6326 5.07902 15.4381 5 15.2353 5L6.82373 5C6.70905 4.99996 6.59583 5.02521 6.49246 5.0739C6.38908 5.12258 6.29818 5.19345 6.22651 5.28125L3.16775 9.03125C3.05917 9.16427 3 9.3296 3 9.5C3 9.6704 3.05917 9.83573 3.16775 9.96875Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
      <path
        d="M8.76469 10L17.1763 10C17.2909 9.99996 17.4042 10.0252 17.5075 10.0739C17.6109 10.1226 17.7018 10.1934 17.7735 10.2812L20.8322 14.0312C20.9408 14.1643 21 14.3296 21 14.5C21 14.6704 20.9408 14.8357 20.8322 14.9688L17.7735 18.7187C17.7018 18.8066 17.6109 18.8774 17.5075 18.9261C17.4042 18.9748 17.2909 19 17.1763 19H8.76469C8.56188 19 8.36738 18.921 8.22397 18.7803C8.08056 18.6397 8 18.4489 8 18.25L8 10.75C8 10.5511 8.08056 10.3603 8.22397 10.2197C8.36738 10.079 8.56188 10 8.76469 10Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const TeamIcon = (props: HTML_SVG) => {
  return (
    <Icon size="lg" viewBox="0 0 24 24" fill="none" color="white" {...props}>
      <path
        d="M10.0001 12.6667C8.15941 12.6667 6.66675 11.174 6.66675 9.33335V8.66669C6.66675 6.82603 8.15941 5.33337 10.0001 5.33337C11.8407 5.33337 13.3334 6.82603 13.3334 8.66669V9.33335C13.3334 11.174 11.8407 12.6667 10.0001 12.6667Z"
        fill="currentColor"
      />
      <path
        d="M15.3333 5.33337C14.7299 5.33337 14.1799 5.54137 13.7333 5.8787C14.3166 6.65737 14.6666 7.62069 14.6666 8.66669V9.33335C14.6666 9.96868 14.5379 10.5747 14.3066 11.1273C14.6233 11.2593 14.9693 11.3333 15.3333 11.3333C16.8059 11.3333 17.9999 10.1393 17.9999 8.66669V8.00002C17.9999 6.52737 16.8059 5.33337 15.3333 5.33337Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
      <path
        d="M19.226 13.6033C18.37 13.21 16.9027 12.6667 15.3334 12.6667C14.5367 12.6667 13.7667 12.8073 13.0887 12.9967C13.3394 13.0487 13.5907 13.1013 13.8421 13.1653C15.7827 13.66 17.1554 15.3486 17.3034 17.3333H19.3334C19.7014 17.3333 20 17.0346 20 16.6666V14.814C20 14.2927 19.7 13.8207 19.226 13.6033Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
      <path
        d="M15.3333 18.6666H4.66666C4.29866 18.6666 4 18.368 4 18V17.6833C4 16.1666 5.01799 14.832 6.48732 14.4573C7.46998 14.2067 8.68064 14 9.99996 14C11.3193 14 12.5299 14.2067 13.5126 14.4573C14.9819 14.832 15.9999 16.1666 15.9999 17.6833V18C15.9999 18.368 15.7013 18.6666 15.3333 18.6666Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const ResourcesIcon = (props: HTML_SVG) => {
  return (
    <Icon size="lg" viewBox="0 0 24 24" fill="none" color="white" {...props}>
      <path
        d="M17.7826 8.65217V7.43478C17.7826 7.27335 17.7184 7.11852 17.6043 7.00437C17.4901 6.89022 17.3353 6.82609 17.1739 6.82609H12.913L11.6956 5H7.43475C7.27331 5 7.11849 5.06413 7.00433 5.17828C6.89018 5.29244 6.82605 5.44726 6.82605 5.6087V8.65217H17.7826Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
      <path
        d="M19.6087 10.8696C19.6087 10.3173 19.161 9.86956 18.6087 9.86956H6C5.44771 9.86956 5 10.3173 5 10.8696V17.1739C5 17.6582 5.19239 18.1227 5.53485 18.4652C5.87731 18.8076 6.34178 19 6.82609 19H17.7826C18.2669 19 18.7314 18.8076 19.0738 18.4652C19.4163 18.1227 19.6087 17.6582 19.6087 17.1739V10.8696Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const BlogIcon = (props: HTML_SVG) => {
  return (
    <Icon size="lg" viewBox="0 0 24 24" fill="none" color="white" {...props}>
      <path
        d="M8.18182 16.0909V17.0455C8.18182 17.8046 7.422 17.9663 7.20182 17.9975C6.687 17.9835 6.27273 17.5628 6.27273 17.0455V5.27273H15.8182V14.8182H17.0909V4.63636C17.0909 4.28509 16.8058 4 16.4545 4H5.63636C5.28509 4 5 4.28509 5 4.63636V17.0455C5 18.2736 5.99909 19.2727 7.22727 19.2727H16.7727C18.0009 19.2727 19 18.2736 19 17.0455V16.0909H8.18182Z"
        fill="currentColor"
      />
      <path
        d="M13.2727 7.18182H8.81813C8.46667 7.18182 8.18176 7.46673 8.18176 7.81818C8.18176 8.16963 8.46667 8.45454 8.81813 8.45454H13.2727C13.6241 8.45454 13.909 8.16963 13.909 7.81818C13.909 7.46673 13.6241 7.18182 13.2727 7.18182Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
      <path
        d="M13.2727 9.72727H8.81813C8.46667 9.72727 8.18176 10.0122 8.18176 10.3636C8.18176 10.7151 8.46667 11 8.81813 11H13.2727C13.6241 11 13.909 10.7151 13.909 10.3636C13.909 10.0122 13.6241 9.72727 13.2727 9.72727Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
      <path
        d="M13.2727 12.2727H8.81813C8.46667 12.2727 8.18176 12.5576 8.18176 12.9091C8.18176 13.2605 8.46667 13.5455 8.81813 13.5455H13.2727C13.6241 13.5455 13.909 13.2605 13.909 12.9091C13.909 12.5576 13.6241 12.2727 13.2727 12.2727Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
    </Icon>
  );
};

export const DocumentationIcon = (props: HTML_SVG) => {
  return (
    <Icon size="lg" viewBox="0 0 24 24" {...props}>
      <path d="m6 9 6-3 6 3v6l-6 3-6-3V9Z" className="fill-primary-100" />
      <path d="m6 9 6 3v7l-6-3V9Z" className="fill-primary-300" />
      <path d="m18 9-6 3v7l6-3V9Z" className="fill-primary-400" />
    </Icon>
  );
};
